import * as React from "react"
import NotFound from "../components/NotFound";

export default class NotFoundPage extends React.Component {
    render() {
        return (
            <NotFound />
        );
    }
}
